<template>
  <div>
    <head-bar title="Profiel wijzigen">
      <template v-slot:left>
        <head-bar-link @click="onBackClick">
          <img src="../../assets/arrow-left.svg" alt="" aria-hidden="true" width="16" height="12" />
          <span class="visually-hidden">Terug</span>
        </head-bar-link>
      </template>
    </head-bar>

    <app-layout classes="profile">
      <div class="p-3" v-if="user">
        <image-upload
          class="mr-auto ml-auto"
          :thumbnail="user.user.avatar"
          :user-id="user.user.id"
          :is-avatar="true"
          @uploaded="onImageUploaded"
        />
      </div>

      <form
        class="p-2 p-md-3"
        action=""
        method="POST"
        @submit.prevent="onSubmit"
        ref="form"
        v-if="user"
        novalidate
      >
        <Errors :errors="serverErrors" />

        <div class="form-group">
          <label for="first_name">Voornaam</label>
          <input
            class="form-control"
            type="text"
            id="first_name"
            name="first_name"
            :value="user.user.first_name"
            required
          >
          <div class="form-error" v-if="errors.first_name">
            {{ errors.first_name }}
          </div>
        </div>

        <div class="form-group">
          <label for="last_name">Achternaam</label>
          <input
            class="form-control"
            type="text"
            id="last_name"
            name="last_name"
            :value="user.user.last_name"
            required
          >
          <div class="form-error" v-if="errors.last_name">
            {{ errors.last_name }}
          </div>
        </div>

        <div class="form-group">
          <label for="email">E-mailadres</label>
          <input
            class="form-control"
            type="email"
            id="email"
            name="email"
            :value="user.user.email"
            required
            readonly
          >
          <div class="form-error" v-if="errors.email">
            {{ errors.email }}
          </div>
        </div>

        <div class="form-group">
          <label for="formDay">Geboortedatum</label>
          <birthdate
            :min-year="minBirthYear"
            :max-year="maxBirthYear"
            :year="birthdateParts ? birthdateParts[0] : null"
            :month="birthdateParts ? birthdateParts[1] : null"
            :day="birthdateParts ? birthdateParts[2] : null"
          />
          <div class="form-error" v-if="errors.birthdate">
            {{ errors.birthdate }}
          </div>
        </div>

        <div class="form-group row">
          <div class="col">
            <label for="postal_code">Postcode</label>
            <input
              class="form-control"
              type="text"
              id="postal_code"
              name="postal_code"
              v-model="address.postal_code"
              @change="onPostalCodeOrHouseNumberChange"
            >
            <div class="form-error" v-if="errors.postal_code">
              {{ errors.postal_code }}
            </div>
          </div>

          <div class="col">
            <label for="house_number">Huisnummer</label>
            <input
              class="form-control"
              type="text"
              id="house_number"
              name="house_number"
              v-model="address.house_number"
              @change="onPostalCodeOrHouseNumberChange"
            >
            <div class="form-error" v-if="errors.house_number">
              {{ errors.house_number }}
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="street">Straatnaam</label>
          <input
            class="form-control"
            type="text"
            id="street"
            name="street"
            v-model="address.street"
          >
          <div class="form-error" v-if="errors.street">
            {{ errors.street }}
          </div>
        </div>

        <div class="form-group">
          <label for="city">Plaats</label>
          <input
            class="form-control"
            type="text"
            id="city"
            name="city"
            v-model="address.city"
          >
          <div class="form-error" v-if="errors.city">
            {{ errors.city }}
          </div>
        </div>

        <Button
          button-type="submit"
          :show-arrow="false"
          classes="btn--full-width"
          :disabled="profileLoadingState === 'loading'"
        >
          Wijzigingen opslaan
        </Button>
      </form>

      <div class="p-2 p-md-3 text-center">
        <Button type="outline" @click="onDeleteProfile">
          Verwijder dit profiel
        </Button>
      </div>
    </app-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { parseISO, formatISO } from 'date-fns';
import { useToast } from 'vue-toastification';
import AppLayout from '../AppLayout.vue';
import HeadBar from '../../components/HeadBar.vue';
import HeadBarLink from '../../components/HeadBarLink.vue';
import ImageUpload from '../../components/ImageUpload.vue';
import Birthdate from '../../components/Birthdate.vue';
import Button from '../../components/Button.vue';
import Errors from '../../components/Errors.vue';
import pad from '../../helpers/pad';
import retrieveErrorsFromResponse from '../../helpers/retrieveErrorsFromResponse';

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },

  components: {
    Errors,
    Button,
    Birthdate,
    ImageUpload,
    HeadBarLink,
    HeadBar,
    AppLayout,
  },

  data() {
    return {
      minBirthYear: (new Date()).getFullYear() - 100,
      maxBirthYear: (new Date()).getFullYear() - 8,
      errors: {},
      serverErrors: [],
      address: {
        street: '',
        city: '',
        postal_code: '',
        house_number: '',
      },
    };
  },

  created() {
    this.$store.dispatch('fetchUser')
      .then(() => {
        this.address = this.user.address;
      });
  },

  methods: {
    onBackClick() {
      this.$router.go(-1);
    },

    onImageUploaded() {
      this.$store.dispatch('fetchUser');
    },

    parseBirthdate(formData) {
      const parts = [
        formData.get('formYear'),
        pad('00', formData.get('formMonth'), true),
        pad('00', formData.get('formDay'), true),
      ];

      return parseISO(parts.join('-'));
    },

    validateBirthdate(formData) {
      const date = this.parseBirthdate(formData);

      // Make sure the date is valid (in case the user enters something like 30 february)...
      const isInvalid = !(date instanceof Date) || Number.isNaN(date.getTime());

      return !isInvalid;
    },

    onSubmit(event) {
      const formData = new FormData(event.currentTarget);

      this.errors = {};

      if (!formData.get('first_name')) {
        this.errors.first_name = 'Dit veld is verplicht';
      }

      if (!formData.get('last_name')) {
        this.errors.last_name = 'Dit veld is verplicht';
      }

      if (!formData.get('email')) {
        this.errors.email = 'Dit veld is verplicht';
      }

      if (formData.get('postal_code') && formData.get('postal_code').length > 7) {
        this.errors.postal_code = 'Dit is geen geldige postcode';
      }

      if (!this.validateBirthdate(formData)) {
        this.errors.birthdate = 'Dit is geen geldige datum';
      }

      if (!Object.keys(this.errors).length) {
        this.storeForm(formData);
      }
    },

    onDeleteProfile() {
      // eslint-disable-next-line no-alert
      if (!window.confirm('Weet je het zeker?')) {
        return;
      }

      this.$store.dispatch('removeUser')
        .then(() => {
          this.$router.push({ name: 'Login' });
        });
    },

    onPostalCodeOrHouseNumberChange() {
      const formData = new FormData(this.$refs.form);

      const postalCode = formData.get('postal_code');
      const houseNumber = formData.get('house_number');

      if (!postalCode || postalCode.length < 6 || !houseNumber) {
        return;
      }

      this.$store.dispatch('loadAddress', [postalCode, houseNumber])
        .then((addressInformation) => {
          if (typeof addressInformation.street !== 'undefined') {
            this.address.street = addressInformation.street;
          }

          if (typeof addressInformation.city !== 'undefined') {
            this.address.city = addressInformation.city;
          }
        });
    },

    storeForm(formData) {
      this.serverErrors = [];

      formData.append('birthdate', formatISO(this.parseBirthdate(formData)));

      this.$store.dispatch('storeProfileForm', formData)
        .then(() => {
          this.$router.push({ name: 'Settings' });

          this.toast.success('Profiel opgeslagen', {
            timeout: 2500,
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);

          if (!err.response) {
            this.errors = ['Er ging iets mis. Probeer het later opnieuw.'];
            return;
          }

          this.serverErrors = retrieveErrorsFromResponse(err.response);
        });
    },
  },

  computed: {
    ...mapGetters([
      'user',
      'birthdate',
      'birthdateParts',
      'profileLoadingState',
    ]),
  },
};
</script>

<style>
.app-layout.profile {
  background-color: #fff;
  padding-top: 3.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  font-weight: 500;
  color: var(--purple);
  margin-bottom: .375rem;
  display: block;
}

.form-error {
  color: var(--primary);
  margin-top: .5rem;
  font-weight: 500;
}
</style>
